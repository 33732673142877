import steering from './steering-wheel.svg';
import './App.css';

function App() {
    return (<div className="App">
        <header className="App-header">
            <img src={steering} className="App-logo" alt="logo"/>
            <p className={"pulsate"}>
                Coming soon...
            </p>
        </header>
    </div>);
}

export default App;
